<mat-spinner *ngIf="spinner || jiraSpin()"></mat-spinner>
<div id="options" *ngIf="!(spinner || jiraSpin())">
  <form class="form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">

    <div class="help_button">
      <button class="indigo" [style.background-color]="theme.getColor('Main Dark')" mat-raised-button color="primary"
        (click)="openFileManager()"> Aides et configuration
      </button>
    </div>

    <!-- card parametres  -->
    <div id="paramCard">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>
            <b>Parametres</b>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="cds_name">
            <label class="lb_name">Nom du CDS :</label>
            <mat-form-field appearance="outline" class="input-mini">
              <input matInput formControlName="cds" type="text" [readonly]="!isEditing">
            </mat-form-field>
            <button *ngIf="!isEditing" class="round margin-right" (click)="enableEditCDS()">
              <p class="material-icons ">
                edit</p>
            </button>
            <button *ngIf="isEditing" class="round margin-right" (click)="onEditCDS()">
              <p class="material-icons ">
                done</p>
            </button>
          </div>

          <div class="radical_jira">
            <label class="lb_name">Radical du fichier JIRA :</label>
            <mat-form-field appearance="outline" class="input-mini">
              <input matInput formControlName="rad" type="text" [readonly]="!isEditingRad">
            </mat-form-field>

            <button *ngIf="!isEditingRad" class="round" (click)="enableEditRad()">
              <p class="material-icons">edit</p>
            </button>
            <button *ngIf="isEditingRad" class="round" (click)="onEditRad()">
              <p class="material-icons">done</p>
            </button>
          </div>

          <div class="last_extract_jira">
            <label class="lb_name">Dernière extraction JIRA:</label>
            <mat-form-field appearance="outline" class="input-mini">
              <input matInput formControlName="dat" [readonly]="!isEditingDat" type="datetime-local">
            </mat-form-field>
            <button *ngIf="!isEditingDat" class="round" (click)="enableEditDat()">
              <p class="material-icons">edit</p>
            </button>
            <button *ngIf="isEditingDat" class="round" (click)="onEditDat()">
              <p class="material-icons">done</p>
            </button>
          </div>

          <div class="month_pack">
            <label class="lb_name">Mois du pack</label>
            <mat-form-field appearance="outline">
              <mat-label>Année</mat-label>
              <mat-select matInput [(ngModel)]="year" formControlName="year">
                <mat-option></mat-option>
                <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="mSpace">
              <mat-label>Mois</mat-label>
              <mat-select matInput [(ngModel)]="month" formControlName="month">
                <mat-option></mat-option>
                <mat-option *ngFor="let month of months; let index = index" [value]="index">{{month}}</mat-option>
              </mat-select>
            </mat-form-field>

            <button class="button-valider" mat-raised-button color="primary" [disabled]="!isDemarrer"
              (click)="isUpdateMonthYear()">Démarrer
            </button>
          </div>

          <div class="init_date">
            <label class="lb_name">Initialisation le: </label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="initialisation" type="date" [readonly]="true">
            </mat-form-field>
          </div>

          <div class="init_open">
            <label class="lb_name">Ouverture prévue le: </label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="OuverturePrevue" type="date" [readonly]="true">
            </mat-form-field>
          </div>

          <div class="validation-period">
            <label class="lb_name">Période de validation du: </label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="valid_deb" type="date" [readonly]="true">
            </mat-form-field>
            <label class="lb_name"> Au: </label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="valid_fin" type="date" [readonly]="true">
            </mat-form-field>
          </div>

          <div class="billing-method"><label class="lb_name invoice-label">Mode de facturation</label>
            <mat-form-field appearance="outline" color="accent" class="input-small invoice-select">
              <mat-select  [(ngModel)]="invoice" [disabled]="!isEditingInvoice"
                formControlName="invoice">
                <mat-option *ngFor="let listItem of listTypes; let index = index" [value]="listItem"
                  title="{{listTitles[index]}}">
                  {{listItem}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button *ngIf="!isEditingInvoice" class="round invoice-btn" (click)="enableEditInvoice()">
              <p class="material-icons">edit</p>
            </button>
            <button *ngIf="isEditingInvoice" class="round invoice-btn" (click)="onEditInvoice()">
              <p class="material-icons">done</p>
            </button>
          </div>
          <div class="rates">
          <mat-slide-toggle hideIcon (change)="onChangeRate($event)"><label class="lb_name_size">Changer les noms et taux de prélèvement</label></mat-slide-toggle>
        
            <div class="tauxRFA" *ngIf="showRatesDates">
              <label class="lb_name">Date changement taux RFA:
                <mat-form-field appearance="outline" class="input-mini">
                  <input matInput formControlName="rfa" [readonly]="!isEditingRfa" type="date">
                </mat-form-field>
              </label>
              <button *ngIf="!isEditingRfa" class="round" (click)="enableEditRfa()">
                <p class="material-icons">edit</p>
              </button>
              <button *ngIf="isEditingRfa" class="round" (click)="onEditRFA()">
                <p class="material-icons">done</p>
              </button>
            </div>

            <div class="prelevement1" *ngIf="showRatesDates">
              <div class="prelevement1">
              <label class="lb_name">Libellé prélèvement 1:
                <mat-form-field appearance="outline" class="input-mini">
                  <input matInput formControlName="prelev1" [readonly]="!isEditingPrelevement1Name" type="text">
                </mat-form-field>
              </label>
              <button *ngIf="!isEditingPrelevement1Name" class="round" (click)="enableEditPrelevement1Name()">
                <p class="material-icons">edit</p>
              </button>
              <button *ngIf="isEditingPrelevement1Name" class="round" (click)="onEditPrelevement1()">
                <p class="material-icons">done</p>
              </button>
            </div>
            <div class="prelevement1">
              <label class="lb_name">Date d'application du 2nd taux pour le prélèvement 1:
                <mat-form-field appearance="outline" class="input-mini">
                  <input matInput formControlName="dateChgTauxPrelevement1" [readonly]="!isEditingPrelevement1Date" type="date">
                </mat-form-field>
              </label>
              <button *ngIf="!isEditingPrelevement1Date" class="round" (click)="enableEditPrelevement1Date()">
                <p class="material-icons">edit</p>
              </button>
              <button *ngIf="isEditingPrelevement1Date" class="round" (click)="onEditPrelevement1Date()">
                <p class="material-icons">done</p>
              </button>
            </div>
          </div>
            <div class="prelevement2" *ngIf="showRatesDates">
              <div class="prelevement2">
              <label class="lb_name">Libellé prélèvement 2:
                <mat-form-field appearance="outline" class="input-mini">
                  <input matInput formControlName="prelev2" [readonly]="!isEditingPrelevement2Name" type="text">
                </mat-form-field>
              </label>
              <button *ngIf="!isEditingPrelevement2Name" class="round" (click)="enableEditPrelevement2Name()">
                <p class="material-icons">edit</p>
              </button>
              <button *ngIf="isEditingPrelevement2Name" class="round" (click)="onEditPrelevement2()">
                <p class="material-icons">done</p>
              </button>
              </div>
              <div class="prelevement2">
              <label class="lb_name">Date d'application du 2nd taux pour le prélèvement 2:
                <mat-form-field appearance="outline" class="input-mini" >
                  <input matInput formControlName="dateChgTauxPrelevement2" [readonly]="!isEditingPrelevement2Date" type="date" >
                </mat-form-field>
              </label>
              <button *ngIf="!isEditingPrelevement2Date" class="round" (click)="enableEditPrelevement2Date()">
                <p class="material-icons">edit</p>
              </button>
              <button *ngIf="isEditingPrelevement2Date" class="round" (click)="onEditPrelevement2Date()">
                <p class="material-icons">done</p>
              </button>
            </div>
            </div>
          </div>
          
    


          <div class="fusion_pack">
            <mat-slide-toggle hideIcon [checked]="applicationSettings.display_fusion_pack"
              (change)="onChangeDisplayFusionPack($event)"><label class="lb_name_size">Activer l'affichage fusion des
                packs</label></mat-slide-toggle>

            <button class="indigo op-button" [style.background-color]="theme.getColor('Main Dark')" mat-raised-button
              color="primary" (click)="fusionPackModal()" *ngIf="applicationSettings.display_fusion_pack">Fusionner le
              pack
            </button>
          </div>

        </mat-card-content>
      </mat-card>
    </div>
    <br>
    <!-- card operation  -->
    <div class="param_operation_container">
      <mat-card appearance="outlined">
        <section [ngClass]="operationPackState ? 'hideOperationsPack':''">
          <mat-card-header>
            <mat-card-title> <mat-slide-toggle hideIcon
                [checked]="applicationSettings.Operations_in_progress ? true : false"
                (change)="onChangeOperations($event)"></mat-slide-toggle>
              <b>Opérations</b></mat-card-title>
          </mat-card-header>
          <mat-card-content>

            <div class="purge_table">
              <mat-slide-toggle hideIcon (change)="onChangePurgeDemands($event)">
                <label class="lb_name_size">Purger la table des demandes </label></mat-slide-toggle>
              <button mat-mini-fab color="primary" (click)="purgeDemands()"
                [disabled]="!isCheckedOperation || isDemarrer" *ngIf="enablePurge">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

            <div class="import_pack">
              <mat-slide-toggle hideIcon (change)="onChangeImportPack($event)"><label class="lb_name_size">Importer un
                  Pack
                </label></mat-slide-toggle>
              <app-file-input [ngClass]="!isCheckedOperation || isDemarrer ? 'input-disable': 'input-space'"
                [(content)]="packFile" *ngIf="enableImportPack"></app-file-input>
              <button mat-mini-fab color="primary" (click)="importPack()" [disabled]="!isCheckedOperation || isDemarrer"
                *ngIf="enableImportPack">
                <b class="icon-font">P</b>
              </button>
            </div>

            <div class="import_jira">
              <button mat-mini-fab color="primary" (click)="importJira()"
                [disabled]="!isCheckedOperation || isDemarrer">
                <b class="icon-font">J</b>
              </button>
              <label class="lb_name_size">Importer un JIRA </label>
              <app-file-input [(content)]="jiraFile"></app-file-input>
              <button mat-mini-fab color="primary" (click)="importAlert()">
                <b class="icon-font">A</b>
              </button>
              <label class="lb_name_size">Alertes JIRA </label>

              <button class="indigo op-button" [style.background-color]="theme.getColor('Main Dark')" mat-raised-button
                color="primary" (click)="openDirectoryManager()"
                [ngStyle]="config.getConfiguration().experimentalEnable === 'false' ? {'display':'none'}:{}">Import JIRA
                différé
              </button>
              <mat-slide-toggle hideIcon [color]="'primary'" (change)="onEditMig()"
                [checked]="applicationSettings.migration_enabled" formControlName="mig"
                [disabled]="!isCheckedOperation"></mat-slide-toggle>
              <label class="lb_name_size">Activer la migration projet Jira </label>
            </div>

            <div class="Calculate_deadlines">
              <button mat-mini-fab color="primary" (click)="calculate()"
                [disabled]="!isCheckedOperation || isDemarrer || applicationSettings.Closure_in_progress">
                <b class="icon-font">€</b>
              </button>
              <label class="lb_name_size">Calculer les échéances </label>
            </div>

            <div class="netting">
              <button mat-mini-fab color="primary" (click)="initNetting()"
                [disabled]="!isCheckedOperation || isDemarrer">
                <b class="icon-font">N</b>
              </button>
              <label class="lb_name_size"> Préparer le Netting </label>
            </div>

          </mat-card-content>
        </section>
      </mat-card>
    </div>
    <br>
    <!-- card open pack  -->
    <div class="open_close_pack_container">
      <mat-card appearance="outlined" [ngClass]="operationPackState ? 'hideOperationsPack':''">
        <section>
          <mat-card-header>
            <mat-card-title> <mat-slide-toggle hideIcon
                [checked]="applicationSettings.enableCollectedDataTab ? true : false"
                (change)="onChangeOpenPack($event)"></mat-slide-toggle>
              <b>Ouvrir le pack</b></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="pack-operation">
              <mat-slide-toggle hideIcon [color]="'primary'" (change)="onChangeEnableSNCF($event)"
                [checked]="applicationSettings.enableCollectedDataTab  && applicationSettings.Enable_SNCF_Validation ? true : false"
                [disabled]="!isCheckedOpenPack"></mat-slide-toggle>
              <label class="lb_name_size">Ouvrir la validation aux acteurs Métier </label>
            </div>
            <div class="pack-operation">
              <mat-slide-toggle hideIcon [color]="'primary'"
                [checked]="applicationSettings.enableCollectedDataTab  && applicationSettings.Enable_Previous_Year ? true : false"
                [disabled]="!isCheckedOpenPack && !useDefaultBoth"
                (change)="onChangeEnablePreviousYear($event)"></mat-slide-toggle>
              <label class="lb_name_size">Présenter le pack de l'année précédente dans l'échéancier </label>
            </div>
          </mat-card-content>
        </section>
      </mat-card>
      <br>
      <!-- card close pack  -->
      <mat-card appearance="outlined" [ngClass]="operationPackState ? 'hideOperationsPack':''">
        <mat-card-header>
          <mat-card-title><mat-slide-toggle hideIcon [checked]="applicationSettings.Closure_in_progress ? true : false"
              (change)="onChangeClosePack($event)"></mat-slide-toggle>
            <b>Clore le pack</b></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="pack-operation">
            <button mat-mini-fab color="primary" (click)="validateDemands()" [disabled]="!isCheckedClosePack  ">
              <mat-icon>spellcheck</mat-icon>
            </button>
            <label class="lb_name_size">Valider les échéances confirmées </label>
          </div>
          <div class="pack-operation">
            <button mat-mini-fab color="primary" (click)="postponeDemands()" [disabled]="!isCheckedClosePack  ">
              <mat-icon>forward</mat-icon>
            </button>
            <label class="lb_name_size">Reporter les échéances non validées </label>

          </div>
          <div class="pack-operation">
            <button mat-mini-fab color="primary" (click)="downloadFile()" [disabled]="!isChecked">
              <mat-icon>download</mat-icon>
            </button>
            <label class="lb_name_size">Exporter le pack </label>
            <mat-form-field appearance="outline">
              <mat-label>Année de l'export</mat-label>
              <input class="year-input" type="number" matInput formControlName="year_export">
            </mat-form-field>

          </div>
          <div class="pack-operation">
            <button mat-mini-fab color="primary" [disabled]=" !isCheckedClosePack " (click)="onEnableCloture()">
              <mat-icon>stop</mat-icon>
            </button>
            <label class="lb_name_size">Cloture le : </label>
            <mat-form-field appearance="outline"
              [ngClass]="formGroup.value.cloture <= formGroup.value.valid_fin  ? 'borderColor' : '' ">
              <input type="date" matInput [readonly]="!enableCloture" formControlName="cloture">
            </mat-form-field>
            <button [disabled]=" enableCloture && formGroup.value.cloture > formGroup.value.valid_fin  ? false : true "
              class="button-valider" mat-raised-button color="primary" (click)="clotureValid()">Valider
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
</div>