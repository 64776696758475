import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as fileSaver from 'file-saver';
import {
  IInvoiceDemands,
  IInvoiceOrderSummary,
} from 'src/app/shared/models/invoices';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { ApplicationSettingsService } from '../../application-settings/application-settings.service';
import { DialogFacturationNettingComponent } from '../facturation-netting-dialog/dialog-facturation-netting.component';
import { FacturationService } from '../services/facturation.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { ApplicationSettings } from 'src/app/shared/models/application-settings';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-facturation-list',
  templateUrl: './facturation-list.component.html',
  styleUrls: ['./facturation-list.component.less'],
})
export class FacturationListComponent implements OnInit, OnDestroy {
  public dataSummary = new MatTableDataSource<IInvoiceOrderSummary>();
  public dataImported = new MatTableDataSource<IInvoiceDemands>();

  public displayedColumns: string[] = [
    'actions',
    'demandNumber',
    'demandInvoice',
  ];
  public displayedColumnsData: string[] = [
    'demandId',
    'demandNumber',
    'demandCost',
    'demandDescription',
    'demandApp',
    'demandService',
    'demandType',
    'demandInvoice',
    'netting',
    'actions',
  ];

  public currentYearPack!: number;
  public yearPackSelected!: number;
  public years: number[] = [];

  public currentMonth!: number;
  public selectedYear!: number;
  public monthSelected!: string;
  public months: string[] = [];
  public lastMonthClosed!: number;

  public monthNames: string[] = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
  ];

  public canExport = true;
  public totalFacture = 0;
  public demandsExcluded: string[] = [];
  public validation = false;
  public spinner = false;
  private allImportedData: IInvoiceDemands[] = []; // Stocke toutes les données de la table de droite
  private currentDemandReference: string | null = null; // Garde la référence de la demande actuellement sélectionnée
  private readonly subscriptions: Subscription[] = []; // Stocke les abonnements

  constructor(
    private applicationSettingsService: ApplicationSettingsService,
    private facturationService: FacturationService,
    public theme: ThemeService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.initializeYearsAndMonths();
  }

  ngOnDestroy(): void {
    // Nettoyage des abonnements
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
  
  private initializeYearsAndMonths(): void {
    this.currentYearPack =
      this.applicationSettingsService.getApplicationSettings().Pack_current_year;
    for (let i = this.currentYearPack - 1; i <= this.currentYearPack; i++) {
      this.years.push(i);
    }
    this.yearPackSelected = this.currentYearPack;

    this.facturationService.getLastClosedMonth().subscribe({
      next: (data) => {
        this.currentMonth = data;
        this.lastMonthClosed = data;
        this.updateMonths();
        this.selectedYear = parseInt(this.monthSelected.slice(-4))
        if (this.monthSelected && this.yearPackSelected) {
          this.refresh(
            this.yearPackSelected,
            this.selectedYear,
            this.monthNames.indexOf(this.monthSelected.slice(0, -7)) + 1,
          );
        }
      },
      error: (err) => {
        console.log(err);
        this.openErrorSnackBar(
          'Erreur lors de la récupération du mois de facturation',
        );
        this.currentMonth = 1;
      },
    });
  }
  private updateMonths(): void {
    this.months = [];
    const isPackFusionned = this.isPackFusionned();
    const startYear = this.yearPackSelected;
    const endYear = isPackFusionned ? startYear : startYear + 1;
  
    for (let year = startYear; year <= endYear; year++) {
      for (let i = 0; i < 12; i++) {
        this.months.push(this.monthNames[i] + ' - ' + year);
      }
    }
  
    this.monthSelected =
      this.monthNames[this.currentMonth - 1] + ' - ' + this.yearPackSelected;
  }

  private isPackFusionned(): boolean {
    const applicationSettings: ApplicationSettings =
      this.applicationSettingsService.getApplicationSettings();
    return applicationSettings.display_fusion_pack;
  }
  openErrorSnackBar(message: string) {
    this.snackbarService.openSnackBar(message, 'error-snack-bar');
  }

  openSuccessSnackBar(message: string) {
    this.snackbarService.openSnackBar(message);
  }

  changePackYear(year: number): void {
    this.spinner = true;
    this.yearPackSelected = year;
    this.updateMonths();
    this.selectedYear = parseInt(this.monthSelected.slice(-4)); // Extraire l'année du mois sélectionné
    this.currentMonth = this.monthNames.indexOf(this.monthSelected.slice(0, -7)) + 1;
    this.refresh(this.yearPackSelected, this.selectedYear, this.currentMonth);
    this.spinner = false;
  }

  changeMonth(month: string): void {
    this.spinner = true;
    this.monthSelected = month;
    this.currentMonth =
    this.monthNames.indexOf(this.monthSelected.slice(0, -7)) + 1;
    this.selectedYear = parseInt(this.monthSelected.slice(-4)); // Extraire l'année du mois sélectionné
    this.refresh(this.yearPackSelected, this.selectedYear, this.currentMonth);
    this.spinner = false;
  }

  isRetarded(element: any): boolean {
    return element.echeanceRetarded;
  }

  isExceeded(element: any): boolean {
    return element.demandCostExceeded;
  }

  exportPreparationNetting(
    packYear: number,
    year: number,
    month: number,
    excludedList: string[],
    validation: boolean,
  ): void {
    this.spinner = true;
    this.facturationService
      .exportPreparationNetting(packYear, year, month, excludedList, validation)
      .subscribe({
        next: (data) => {
          const blob: any = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          fileSaver.saveAs(
            blob,
            'Mise en Facturation–BDC ' +
              this.monthNames[month - 1] +
              '-' +
              year +
              ' - ' +
              this.applicationSettingsService.applicationSettings.CDS_name +
              ' - ' +
              new Date().toLocaleDateString(),
          );
          this.spinner = false;
        },
        error: (err) => {
          console.log(err);
          this.openErrorSnackBar(
            "Erreur lors de l'exportation de la préparation de la mise en facturation",
          );
          this.spinner = false;
        },
        complete: () => {
          if (validation) {
            this.refresh(this.yearPackSelected, this.selectedYear ,this.currentMonth); // rafraichissement de la page apres la validation
          }
        },
      });
  }

 refresh(packYear: number, year: number, month: number) {
  this.spinner = true;
  // ajouter l'année du mois selectionnée
  this.facturationService.getDemandInvoice(packYear, year, month).subscribe({
    next: (data) => {
      this.allImportedData = data; // Stocke les données complètes
      this.dataImported.data = this.currentDemandReference
        ? this.filterDataImported(this.currentDemandReference)
        : data; // Affiche toutes les données si aucune sélection
      this.spinner = false;
    },
    error: (err) => {
      console.log(err);
      this.openErrorSnackBar(
        'Erreur lors de la récupération du detail de la facturation',
      );
      this.spinner = false;
    },
  });

  this.facturationService.getDemandInvoiceSummary(packYear, year, month).subscribe({
    next: (data) => {
      this.dataSummary.data = data;
      this.totalFacture = data.reduce(
        (sum: number, current: IInvoiceOrderSummary) =>
          sum + current.demandAmount,
        0,
      );
    },
    error: (err) => {
      console.log(err);
      this.openErrorSnackBar(
        'Erreur lors de la récupération des bons de commande',
      );
    },
  });
}

  exculdeDemand(ref: string) {
    const index = this.demandsExcluded.indexOf(ref);
    const elements = document.getElementsByClassName(
      ref,
    ) as HTMLCollectionOf<HTMLElement>;
    const totalToRemove = this.dataSummary.data.find(
      (x) => x.demandReference === ref,
    );
    if (index > -1) {
      this.demandsExcluded.splice(index, 1);
      Array.prototype.forEach.call(elements, function (el) {
        el.style.textDecoration = 'none';
      });
      if (totalToRemove !== undefined) {
        this.totalFacture = this.totalFacture + totalToRemove.demandAmount;
      }
    } else {
      Array.prototype.forEach.call(elements, function (el) {
        el.style.textDecoration = 'line-through';
      });
      this.demandsExcluded.push(ref);
      if (totalToRemove !== undefined) {
        this.totalFacture = this.totalFacture - totalToRemove.demandAmount;
      }
    }
  }

  dialogChangeNetting(dNum: string, dRef: string) {
    const dialogRef = this.dialog.open(DialogFacturationNettingComponent, {
      width: '600px',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: { dNumber: dNum, dReference: dRef },
    });
    dialogRef.afterClosed().subscribe({
      next: (value) => {
        this.facturationService.getNettingFromDemand(value.ndemand).subscribe({
          next: (res) => {
            const elements = document.getElementById(
              value.ndemand,
            ) as HTMLElement;
            if (res == null) elements.textContent = 'Aucun';
            else elements.textContent = res.name + '';
          },
          error: (err) => {
            console.log(err);
            this.openErrorSnackBar('Erreur lors de la récupération du netting');
          },
        });
      },
      error: (err) => {
        console.log('Aucune modification apportée au compte de netting');
      },
    });
  }

  // Sélectionne une demande dans la table de gauche et filtre les données de la table de droite
  onSelectDemand(demandReference: string): void {
    if (this.currentDemandReference === demandReference) {
      // Si la demande sélectionnée est déjà la même, réinitialise le filtre
      this.currentDemandReference = null;
      this.dataImported.data = this.allImportedData;
    } else {
      // Sinon, filtre les données
      this.currentDemandReference = demandReference;
      this.dataImported.data = this.filterDataImported(demandReference);
    }
  }
  
  // Filtre les données de la table de droite
  private filterDataImported(demandReference: string): IInvoiceDemands[] {
    return this.allImportedData.filter(
      (item) => item.demandReference === demandReference,
    );
  }

  get isButtonDisabled(): boolean {

    if (!this.monthSelected) {
      return true; // desactivation si aucun mois selectionné ( chargement de la page)
    }

    const selectedYear = this.yearPackSelected;
    const selectedMonth =
      this.monthNames.indexOf(this.monthSelected.slice(0, -7)) + 1;

    const currentYear = new Date().getFullYear();
    const currentMonth = this.lastMonthClosed;
    // Désactiver si la table est vide ou si la date sélectionnée est apres le dernier mois cloturé
    return (
      !this.dataImported?.data?.length ||
      !this.dataSummary?.data?.length ||
      selectedYear > currentYear ||
      (selectedYear === currentYear && selectedMonth > currentMonth)
    );
  }
}
